import React from 'react'
import TableVar from './TableVar'
import Photos from './Photos'
import SelectVar from './SelectVar'

export default function Dashboard({ photoIndex, showPhoto, applyId, handleGetPhotoId, selectedVar, selectVar, refreshApproval, getVar, confirmRegUlang, approval, numShow, compareVar }) {
    // const [loading, setLoading] = useState(0);
    // useEffect(() => {
    //     setLoading(loadingStatus)
    // }, []);
    const approvalList = approval.length ? (
        <div>
        <SelectVar selectedVar={selectedVar} selectVar={selectVar}/>
        {approval.map((item,index) => {
            return (
                <div className="approval collection-item" key={index}>
                    <div className="card-panel hoverable" onClick={() => {getVar(item.start_user_id, item.task_id, item.execution_id)}}>
                        {index+1}. {item.username} telah melakukan register ulang pada {item.created_at.substr(0,10)}
                    </div>
                    {item.task_id === numShow ? (
                    <div className="compare_var">
                        {compareVar.map((comparableVar, idx) => (
                        <div key={idx}>
                            <TableVar compareVar={comparableVar.comVar}/>
                            &nbsp;
                            <div className="center-align">
                                <button className="waves-effect waves-light btn" onClick={(e) => 
                                    handleGetPhotoId(comparableVar.cusId, comparableVar.userId)}>
                                    Tampilkan Foto
                                </button>
                            </div>
                            &nbsp;
                            {showPhoto && (comparableVar.userId === photoIndex) && (
                            <div>
                                <Photos cusId={comparableVar.cusId} applyId={applyId} docId={comparableVar.docId}/>
                                <div className="right-align">
                                    <button className="btn red" onClick={(e) => 
                                        window.confirm("Yakin untuk reject register ulang dari " + item.username + "?") &&
                                        confirmRegUlang(comparableVar.userId, item.start_user_id, item.task_id, item.execution_id, "rejected")} 
                                        value="rejected">reject</button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <button className="btn green" onClick={(e) => 
                                        window.confirm("Yakin untuk approve register ulang dari " + item.username + "?") && 
                                        confirmRegUlang(comparableVar.userId, item.start_user_id, item.task_id, item.execution_id, "approved")} 
                                        value="approved">approve</button>
                                </div>
                                &nbsp;
                            </div>
                            )}
                            {/*&nbsp;*/}{/*&nbsp;*/}{/*&nbsp;*/}{/*&nbsp;*/}{/*&nbsp;*/}
                        </div>
                        ))}
                        {/*&nbsp;*/}{/*&nbsp;*/}{/*&nbsp;*/}
                    </div>
                    ) : (
                        null
                    )}
                </div>
            )
        })}
        </div>
    ) : (
        <div>
            <p className="center">Semua permintaan register ulang telah diproses</p>
            <p className="center">
                <button className="center waves-effect waves-light btn" onClick={() => {
                    refreshApproval();
                }} value="refresh">refresh</button>
            </p>
        </div>
    )

    return (
        <div className="dashboard collection">
            {approvalList}
        </div>
    )
}
