module.exports = {
  img: {
    prefix: `${process.env.REACT_APP_IMG_HOST}/${process.env.REACT_APP_IMG_BUCKET}`,
    host: `${process.env.REACT_APP_IMG_HOST}`,
    bucket: `${process.env.REACT_APP_IMG_BUCKET}`,
    root: `${process.env.REACT_APP_IMG_ROOT}`,
    folder_reg: `${process.env.REACT_APP_IMG_FOLDER_REG}`,
    folder_app: `${process.env.REACT_APP_IMG_FOLDER_APP}`,
  },
  api: {
    login: {
      url: `${process.env.REACT_APP_GATEWAY_LOGIN}`
    },
    get_all_approval: {
      url: `${process.env.REACT_APP_GATEWAY_APPROVAL}`
    },
    get_photo_id: {
      url: `${process.env.REACT_APP_GATEWAY_PHOTO}`
    },
    get_detail: {
      url: `${process.env.REACT_APP_GATEWAY_VARIABLE}`
    },
    confirm_register_ulang: {
      url: `${process.env.REACT_APP_GATEWAY_CONFIRM}`
    }
  }
};
