import React, { Component } from 'react'

export default class SelectVar extends Component {
    state = {
        nama: this.props.selectedVar.nama,
        noktp: this.props.selectedVar.noktp,
        noimei: this.props.selectedVar.noimei,
        nohp: this.props.selectedVar.nohp,
        kodept: this.props.selectedVar.kodept,
    }

    handleChange = (vr) => {
        this.setState({
            [vr]: !this.state[vr]
        }, () => {this.props.selectVar(this.state)})
    }

    render() {
        return (
            <div>
                <form action="#">
                    <div style={{display:"flex", paddingLeft:"10px", paddingRight:"10px"}}>
                        <p>
                            <span><b>Pilih Variabel:</b></span>
                        </p>
                        <p style={{marginLeft:"40px"}}>
                            <label>
                                <input type="checkbox" className="filled-in" defaultChecked={this.state.nama} onClick={
                                    () => this.handleChange("nama")}/>
                                <span>Username</span>
                            </label>
                        </p>
                        <p style={{marginLeft:"40px"}}>
                            <label>
                                <input type="checkbox" className="filled-in" defaultChecked={this.state.noktp} onClick={
                                    () => this.handleChange("noktp")}/>
                                <span>Nomor KTP</span>
                            </label>
                        </p>
                        <p style={{marginLeft:"40px"}}>
                            <label>
                                <input type="checkbox" className="filled-in" defaultChecked={this.state.noimei} onClick={
                                    () => this.handleChange("noimei")}/>
                                <span>Nomor IMEI</span>
                            </label>
                        </p>
                        <p style={{marginLeft:"40px"}}>
                            <label>
                                <input type="checkbox" className="filled-in" defaultChecked={this.state.nohp} onClick={
                                    () => this.handleChange("nohp")}/>
                                <span>Nomor HP</span>
                            </label>
                        </p>
                        <p style={{marginLeft:"40px"}}>
                            <label>
                                <input type="checkbox" className="filled-in" defaultChecked={this.state.kodept} onClick={
                                    () => this.handleChange("kodept")}/>
                                <span>Kode Perusahaan</span>
                            </label>
                        </p>
                    </div>
                </form>
            </div>
        )
    }
}
