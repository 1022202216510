import React, { useRef, useEffect } from 'react'

const cfg = require('./Config');

const useScroll = () => {
    const htmlElRef = useRef(null)
    const executeScroll = () => window.scrollTo(0, htmlElRef.current.offsetTop)
  
    return [executeScroll, htmlElRef]
}

function Photos({cusId, applyId, docId}) {
    const [executeScroll, htmlElRef] = useScroll()
    useEffect(executeScroll, []) // Runs after component mounts

    return (
        <div ref={htmlElRef}>
        <div className="row">
            <div className="col s12 m4">
                <div className="card">
                    <div className="card-image">
                        <img
                            className="photo"
                            src={`${cfg.img.prefix}/${cfg.img.root}/${cusId}/${cfg.img.folder_app}/${applyId}/ktp.jpg`}
                            alt="FOTO TIDAK ADA"
                            style={{ display: 'block' }}
                        />
                    </div>
                    <div className="card-action">
                        <a href={`${cfg.img.prefix}/${cfg.img.root}/${cusId}/${cfg.img.folder_app}/${applyId}/ktp.jpg`}
                            target="_blank" rel="noopener noreferrer"
                            style={{color: 'green', fontWeight: 'bold'}}>
                            Foto Lama KTP
                        </a>
                    </div>
                </div>
            </div>
            <div className="col s12 m4">
                <div className="card">
                    <div className="card-image">
                        <img
                            className="photo"
                            src={`${cfg.img.prefix}/${cfg.img.root}/${cusId}/${cfg.img.folder_app}/${applyId}/payslip.jpg`}
                            alt="FOTO TIDAK ADA"
                            style={{ display: 'block' }}
                        />
                    </div>
                    <div className="card-action">
                        <a href={`${cfg.img.prefix}/${cfg.img.root}/${cusId}/${cfg.img.folder_app}/${applyId}/payslip.jpg`}
                            target="_blank" rel="noopener noreferrer"
                            style={{color: 'green', fontWeight: 'bold'}}>
                            Foto Lama Slip Gaji
                        </a>
                    </div>
                </div>
            </div>
            <div className="col s12 m4">
                <div className="card">
                    <div className="card-image">
                        <img
                            className="photo"
                            src={`${cfg.img.prefix}/${cfg.img.root}/${cusId}/${cfg.img.folder_app}/${applyId}/selfie.jpg`}
                            alt="FOTO TIDAK ADA"
                            style={{ display: 'block' }}
                        />
                    </div>
                    <div className="card-action">
                        <a href={`${cfg.img.prefix}/${cfg.img.root}/${cusId}/${cfg.img.folder_app}/${applyId}/selfie.jpg`}
                            target="_blank" rel="noopener noreferrer" 
                            style={{color: 'green', fontWeight: 'bold'}}>
                            Foto Diri Lama
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col s12 m4">
                <div className="card">
                    <div className="card-image">
                        <img
                            className="photo"
                            src={`${cfg.img.prefix}/${cfg.img.root}/${cfg.img.folder_reg}/${docId}/ktp.jpg`}
                            alt="FOTO TIDAK ADA"
                            style={{ display: 'block' }}
                        />
                    </div>
                    <div className="card-action">
                        <a href={`${cfg.img.prefix}/${cfg.img.root}/${cfg.img.folder_reg}/${docId}/ktp.jpg`}
                            target="_blank" rel="noopener noreferrer"
                            style={{color: 'green', fontWeight: 'bold'}}>
                            Foto Baru KTP
                        </a>
                    </div>
                </div>
            </div>
            <div className="col s12 m4">
                <div className="card">
                    <div className="card-image">
                        <img
                            className="photo"
                            src={`${cfg.img.prefix}/${cfg.img.root}/${cfg.img.folder_reg}/${docId}/payslip.jpg`}
                            alt="FOTO TIDAK ADA"
                            style={{ display: 'block' }}
                        />
                    </div>
                    <div className="card-action">
                        <a href={`${cfg.img.prefix}/${cfg.img.root}/${cfg.img.folder_reg}/${docId}/payslip.jpg`}
                            target="_blank" rel="noopener noreferrer"
                            style={{color: 'green', fontWeight: 'bold'}}>
                            Foto Baru Slip Gaji
                        </a>
                    </div>
                </div>
            </div>
            <div className="col s12 m4">
                <div className="card">
                    <div className="card-image">
                        <img
                            className="photo"
                            src={`${cfg.img.prefix}/${cfg.img.root}/${cfg.img.folder_reg}/${docId}/selfie.jpg`}
                            alt="FOTO TIDAK ADA"
                            style={{ display: 'block' }}
                        />
                    </div>
                    <div className="card-action">
                        <a href={`${cfg.img.prefix}/${cfg.img.root}/${cfg.img.folder_reg}/${docId}/selfie.jpg`}
                            target="_blank" rel="noopener noreferrer" 
                            style={{color: 'green', fontWeight: 'bold'}}>
                            Foto Diri Baru
                        </a>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Photos
