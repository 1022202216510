import React from 'react'

function renderTableData(body) {
    return body.map((v,i) => {
        return (
            <tr key={i}>
                <td>{v.username}</td>
                <td>{v.mobile_number}</td>
                <td>{v.ktp}</td>
                <td>{v.firstName}</td>
                <td>{v.employer_code}</td>
                <td>{v.employee_id}</td>
                <td>{v.email}</td>
                <td>{v.device}</td>
            </tr>
        )
    })
}

function renderTableHead(head) {
    return head.map((v,i) => {
        return <th className="white-text" key={i}>{v}</th>
    })
}

export default function TableVar({compareVar}) {
    let head = []
    let bodyValueSSO = {}
    let bodyValue = {}
    compareVar.map(item => {
            if (item.name === 'username') {
                head.push('Username')
            } else if (item.name === 'ktp') {
                head.push('Nomor KTP')
            } else if (item.name === 'device') {
                head.push('Nomor Imei')
            } else if (item.name === 'mobile_number') {
                head.push('Nomor HP')
            } else if (item.name === 'employer_code') {
                head.push('Kode Perusahaan')
            } else if (item.name === 'firstName') {
                head.push('Nama')
            } else if (item.name === 'employee_id') {
                head.push('Nomor Pegawai')
            } else if (item.name === 'email') {
                head.push('Email')
            } else {
                head.push('Null')
            }
            return null
    })
    compareVar.map(item => (
        bodyValueSSO[item.name] = item.valueSSO //{...bodyValueSSO, [item.name]: item.valueSSO}
    ))
    compareVar.map(item => (
        bodyValue[item.name] = item.value
    ))
    let body = [bodyValueSSO, bodyValue]

    return (
        <div>
            <table className="responsive-table highlight">
                <tbody>
                    <tr className="green">{renderTableHead(head)}</tr>
                    {renderTableData(body)}
                </tbody> 
            </table>
        </div>
    )
}
