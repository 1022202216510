import React, { Component } from 'react'
import qs from 'qs'
import axios from 'axios'

const cfg = require('./Config');

export default class Login extends Component {
    state = {
        username: '',
        password: '',
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault()
        const data = {
            username: this.state.username,
            password: this.state.password,
        }
        this.props.setLoading(true)
        this.webLogin(data).then(res => {
            if (!res.error) {
                window.localStorage.setItem('tooltoken', res.data.token)
            }
            this.props.isLogin(!res.error)
        })
    }

    onKeyPress(event) {
        if (event.which === 13 /* Enter */) {
          event.preventDefault();
        }
    }

    // axios method
    webLogin = async (data) => {
        const body = qs.stringify(data)
        let res
        try {
            const confirm = await axios({
                method: 'post',
                url: cfg.api.login.url,
                data: body,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                }
            })
            res = confirm.data
        } catch (error) {
            res = {
                "error": true,
                "message": "gagal login"
            }
        }
        return res
    }

    render() {
        return (
            <div className="container">
            <div className="card-panel" style={{
                marginTop: '10%',
                marginLeft: '20%',
                marginRight: '20%'
            }}>
                <form className="form" onKeyPress={this.onKeyPress}>
                    <label>Username:</label>
                    <input type="text" id="username" onChange={this.handleChange} />
                    <label>Password:</label>
                    <input type="password" id="password" onChange={this.handleChange} />
                    &nbsp;
                    <div className="center">
                        <button className="waves-effect waves-light btn" onClick={this.handleSubmit}>Submit</button>
                    </div>
                </form>
            </div>
            </div>
        )
    }
}
