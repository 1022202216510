import React, { Component } from 'react'
import Header from './Header';
import Dashboard from './Dashboard';
import Login from './Login';
import axios from 'axios'
import qs from 'qs'

const cfg = require('./Config');

export default class App extends Component {
  state = {
    approval: [],
    numShow: -1,
    compareVar: {},
    login: false,
    loading: false,
    nama: false,
    noktp: false,
    noimei: false,
    nohp: false,
    kodept: false,
    applyId: "",
    showPhoto: false,
    photoIndex: "",
    confirmError: false,
  }

  confirmRegUlang = (user_id, username, task_id, exe_id, status) => {
    const data = {
      regul_status: status,
      operator_id: 'gcs01',
      namapengguna: username,
      user_id: user_id,
      exe_id: exe_id,
      task_id: task_id
    }
    this.setState({
      loading: true
    })
    this.sendConfirmRegUlang(data).then(res => {
      if (res.error) {
        // show popup gagal
        // console.log(res.message)
        this.setState({
          confirmError: true
        })
      } else {
        // show popup sukses
        // console.log(res.message)
        this.setState({
          confirmError: false
        })
      }
      this.getAllApproval().then(res => {
        if (res.unauthorized) {
          this.setState({
            approval: res.data,
            numShow: -1,
            compareVar: {},
            loading: false,
            showPhoto: false,
            login: false
          })
        } else {
          this.setState({
            approval: res.data,
            numShow: -1,
            compareVar: {},
            loading: false,
            showPhoto: false
          })
        }
      })
    })
  }

  refreshApproval = () => {
    this.setState({
      loading: true
    })
    this.getAllApproval().then(res => {
      if (res.unauthorized) {
        this.setState({
          approval: res.data,
          numShow: -1,
          compareVar: {},
          loading: false,
          showPhoto: false,
          confirmError: false,
          login: false
        })
      } else {
        this.setState({
          approval: res.data,
          numShow: -1,
          compareVar: {},
          loading: false,
          showPhoto: false,
          confirmError: false
        })
      }
    })
  }

  setLoading = (status) => {
    this.setState({
      loading: status
    })
  }

  isLogin = (status) => {
    if (status) {
      this.getAllApproval().then(res => {
        if (res.unauthorized) {
          this.setState({
            approval: res.data,
            numShow: -1,
            compareVar: {},
            // login: status,
            loading: false,
            showPhoto: false,
            confirmError: false,
            login: false
          })
        } else {
          this.setState({
            approval: res.data,
            numShow: -1,
            compareVar: {},
            // login: status,
            loading: false,
            showPhoto: false,
            confirmError: false,
            login: true
          })
        }
      })
    } else {
      this.setState({
        login: false,
        loading: false,
        confirmError: false
      })
    }
  }

  setLogout = (status) => {
    window.localStorage.removeItem('tooltoken')
    this.setState({
      login: !status,
      numShow: -1,
      compareVar: {},
      approval: [],
      showPhoto: false,
      confirmError: false
    })
  }

  selectVar = (vars) => {
    const { nama, noktp, noimei, nohp, kodept } = vars
    this.setState({
      nama,
      noktp,
      noimei,
      nohp,
      kodept
    })
  }

  getVar = (username, task_id, exe_id) => {
    const data = {
      inst_id: 'REGUL-' + username,
      namapengguna: username,
      exe_id: exe_id,
      task_id: task_id,
      isUsername: this.state.nama,
      isNoktp: this.state.noktp,
      isNoimei: this.state.noimei,
      isNohp: this.state.nohp,
      isKodept: this.state.kodept
    }
    let numShow = -1
    let compareVar = {}
    this.setState({
      loading: true,
      showPhoto: false,
      confirmError: false
    })
    this.getAllVariable(data).then(res => {
      if (res.data.length > 0) {
        numShow = task_id
        compareVar = res.data
      }
      if (res.unauthorized) {
        this.setState({
          numShow,
          compareVar,
          loading: false,
          confirmError: false,
          login: false
        })
      } else {
        this.setState({
          numShow,
          compareVar,
          loading: false,
          confirmError: false
        })
      }
    })
  }

  componentDidMount() {
    this.setState({
      loading: true
    })
    this.getAllApproval().then(res => {
      if (res.unauthorized) {
        this.setState({
          approval: res.data,
          loading: false,
          confirmError: false,
          login: false
        })
      } else {
        this.setState({
          approval: res.data,
          loading: false,
          confirmError: false,
          login: true
        })
      }
    })
  }

  // axios method
  getAllApproval = async () => {
    let res
    try {
      const allApproval = await axios.get(cfg.api.get_all_approval.url, {
        headers: {
          'authorization': 'Bearer ' + window.localStorage.getItem('tooltoken')
        }
      })
      res = allApproval.data
    } catch (error) {
      console.log(error)
      if (error.response.status === 401 || error.response.status === 403) {
        res = {unauthorized: true, data: []}
      } else {
        res = {unauthorized: false, data: []}
      }
    }
    return res
  }

  handleGetPhotoId = (customerId, userId) => {
    const data = {
      customer_id: customerId
    }
    this.setState({
      loading: true
    })
    this.getPhotoId(data).then(res => {
      if (res.unauthorized) {
        this.setState({
          applyId: res.data.apply_id,
          loading: false,
          showPhoto: true,
          photoIndex: userId,
          confirmError: false,
          login: false
        })
      } else {
        this.setState({
          applyId: res.data.apply_id,
          loading: false,
          showPhoto: true,
          photoIndex: userId,
          confirmError: false
        })
      }
    })
  }

  getPhotoId = async (data) => {
    const body = qs.stringify(data)
    let res
    try {
      const photoId = await axios({
        method: 'post',
        url: cfg.api.get_photo_id.url,
        data: body,
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
          'authorization': 'Bearer ' + window.localStorage.getItem('tooltoken')
        }
      })
      res = photoId.data
    } catch (error) {
      console.log(error)
      if (error.response.status === 401 || error.response.status === 403) {
        res = {unauthorized: true, data: ""}
      } else {
        res = {unauthorized: false, data: ""}
      }
    }
    return res
  }

  // axios method
  getAllVariable = async (data) => {
    const body = qs.stringify(data)
    let res
    try {
      const allVariable = await axios({
        method: 'post',
        url: cfg.api.get_detail.url,
        data: body,
        headers: {
          // 'Content-Type': 'application/json',
          // 'Accept': 'application/json',
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
          'authorization': 'Bearer ' + window.localStorage.getItem('tooltoken')
          // 'authorization': resToken,
        }
      })
      res = allVariable.data
    } catch (error) {
      console.log(error)
      if (error.response.status === 401 || error.response.status === 403) {
        res = {unauthorized: true, data: []}
      } else {
        res = {unauthorized: false, data: []}
      }
    }
    return res
  }

  // axios method
  sendConfirmRegUlang = async (data) => {
    const body = qs.stringify(data)
    let res
    try {
      const confirm = await axios({
        method: 'post',
        url: cfg.api.confirm_register_ulang.url,
        data: body,
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
          'authorization': 'Bearer ' + window.localStorage.getItem('tooltoken')
        }
      })
      res = confirm.data
    } catch (error) {
      console.log(error)
      if (error.response.status === 401 || error.response.status === 403) {
        res = {
          unauthorized: true,
          "error": true,
          "message": "GAGAL melakukan konfirmasi, silakan ulangi kembali"
        }
      } else {
        res = {
          unauthorized: false,
          "error": true,
          "message": "GAGAL melakukan konfirmasi, silakan ulangi kembali"
        }
      }
    }
    return res
  }

  show = () => {
    if (this.state.login) {
      return (
        <>
          <h3 className="center green-text">Daftar Permintaan Register Ulang</h3>
          &nbsp;
          <Dashboard
            photoIndex={this.state.photoIndex}
            showPhoto={this.state.showPhoto}
            applyId={this.state.applyId}
            handleGetPhotoId={this.handleGetPhotoId}
            selectedVar={{
              nama: this.state.nama,
              noktp: this.state.noktp,
              noimei: this.state.noimei,
              nohp: this.state.nohp,
              kodept: this.state.kodept
            }}
            selectVar={this.selectVar}
            refreshApproval={this.refreshApproval}
            getVar={this.getVar}
            confirmRegUlang={this.confirmRegUlang}
            approval={this.state.approval}
            numShow={this.state.numShow}
            compareVar={this.state.compareVar} />
        </>
      )
    } else {
      return (
        <>
          <h3 className="center green-text">Login</h3>
          <Login setLoading={this.setLoading} isLogin={this.isLogin} />
        </>
      )
    }
  }

  render() {
    return (
      <div>
      {this.state.loading ? (
        <div className="row" style={{marginTop: '20%'}}>
          <div className="col s12 m3"></div>
          <div className="col s12 m6">
            <div className="progress">
              <div className="indeterminate"></div>
            </div>
          </div>
          <div className="col s12 m3"></div>
        </div>
      ) : (
      <div>
      <Header login={this.state.login} setLogout={this.setLogout} />
      <div className="App container">
        {this.show()}
      </div>
      {this.state.confirmError && window.alert("Gagal Melakukan Konfirmasi, Silakan Ulangi Kembali!")}
      </div>
      )}
      </div>
    );
  }
}
