import React from 'react'
import logo from './logo-pap-white.png';

export default function Header({ login, setLogout }) {
    const showLogout = login ? (
        <button className="btn-float black-text white" onClick={(e) => 
            window.confirm("Ingin melanjutkan logout?") &&
            setLogout(true)}>Logout</button>
    ) : (
        null
    )
    return (
        <div className="row white-text green darken-4">
            <div className="col s4 left-align">
                <img src={logo} height="60" alt="Logo Papitupi" />
            </div>
            <div className="col s4 center"><h5>Papitupi-Tools</h5></div>
            <div className="col s4 right-align">
                {showLogout}
            </div>
        </div>
    )
}
